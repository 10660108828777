import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';
import {navigate, Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import useBreakpoint from '../../Hooks/useBreakPoint';
import ReactDelighters from '../../Components/ReactDelighters';
import Carousel from '../../Components/CarouselWrapper';

function Landing(props) {
  const [actions] = useOutlet('actions');
  const [bannerDimension, setBannerDimension] = React.useState(null);
  const [bannerList, setBannerList] = React.useState([]);
  const [articles, setArticles] = React.useState([]);
  const [dimension] = useOutlet('dimension');

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem?.getBoundingClientRect();
    setBannerDimension(box);
  }, [dimension.innerWidth]);

  React.useEffect(() => {
    async function fetchBannerList() {
      actions.setLoading(true);
      let resp = await actions.fetchDocuments('banner');
      let bannerList = resp.results.filter((banner) => banner.type === 'home');
      setBannerList(bannerList);
      actions.setLoading(false);
    }
    async function fetchArticleData() {
      try {
        actions.setLoading(true);
        let resp = await actions.fetchDocuments('Article_Default', {}, [
          '-created',
        ]);
        let dataList = resp.results.map((it) => {
          if (!it.id && it._id) {
            it.id = it._id.$oid;
          }
          return {...it};
        });
        setArticles(dataList);
      } catch (ex) {
        console.warn(ex);
      } finally {
        actions.setLoading(false);
      }
    }

    fetchArticleData();
    fetchBannerList();
  }, [actions]);

  React.useEffect(() => {}, [actions]);

  let itemWidth = (dimension.innerWidth - 200 - 40) / 3;
  let witnessItemWidth = (1240 - 200) / 4;

  return (
    <Wrapper>
      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38, position: 'relative'}}>
        <Carousel
          width={bannerDimension?.width}
          height={bannerDimension?.width * 0.38}
          items={bannerList.sort((a, b) => a.order - b.order)}
          style={{position: 'absolute', top: 0, left: 0}}
        />
      </div>
      <section className="section">
        <div className="subtitle">NEWS</div>
        <div className="title" style={{display: 'flex'}}>
          最新消息
          <div style={{flex: 1}} />
          <p className="read-more-btn" onClick={() => navigate('/articles')}>
            查看更多
          </p>
        </div>
        <section className="grid">
          {console.log('articles-------', articles)}
          {articles
            .filter(
              (a) => (a?.label && a.label.indexOf('最新消息') >= 0) || false,
            )
            .slice(0, 3)
            .map((item, idx) => (
              <div
                onClick={() => {
                  navigate(`/article?id=${item.id}`);
                }}
                className="grid-item"
                key={idx}
                style={{marginBottom: 50}}>
                <div
                  className="grid-item-img"
                  style={{
                    backgroundImage: `url(${item.image})`,
                  }}
                />
                <p className="grid-item-date" style={{marginTop: 10}}>
                  {new Date(item.created).toLocaleDateString('en-ZA')}
                </p>
                <p className="grid-item-title" style={{overflow: 'visible'}}>
                  {item.title}
                </p>
                <p
                  className="grid-item-outline outline"
                  style={{marginTop: 10}}>
                  {item.outline}
                </p>
              </div>
            ))}
        </section>
      </section>
      <section className={`light-bkg`}>
        <div
          className={`constraint ${dimension.rwd === 'desktop' ? 'row' : ''}`}>
          <div style={{flexBasis: dimension.innerWidth / 3}}>
            <img
              src="../../images/landing-about.jpg"
              style={{width: '100%', height: '100%', objectFit: 'cover'}}
            />
          </div>
          <div className="section" style={{flex: 1}}>
            <div className="subtitle">ABOUT US</div>
            <div className="title">關於我們</div>
            <p
              className="content"
              style={{
                marginTop: 25,
              }}>{`微媞時尚診所以優質完善的醫療技術與美學品味，打造眾多成功案例。專屬客製化諮詢服務，讓每位顧客都能在輕鬆且專業的諮詢對談中，體認到不同以往的自我價值。協助客戶實現心中的美麗嚮往是我們的使命，而平實價格也讓美麗輕鬆實現。

2011年台北院成立
2013年起完成ISO專業品質管理系統認證
2014年入選FG百大醫美
2014年台中院成立`}</p>
            <p
              className="read-more-btn"
              onClick={() => navigate('/about')}
              style={{marginTop: 40}}>
              查看更多
            </p>
          </div>
        </div>
      </section>

      <section className="section" style={{paddingBottom: 0}}>
        <div className="subtitle">WITNESS</div>
        <div className="title" style={{display: 'flex', marginBottom: 20}}>
          美麗見證
          <div style={{flex: 1}} />
          <p className="read-more-btn" onClick={() => navigate('/witness')}>
            查看更多
          </p>
        </div>

        <div className="scroll-constrain">
          <div className="scroll-wrapper">
            {articles
              .filter(
                (a) => (a?.label && a.label.indexOf('美麗見證') >= 0) || false,
              )
              .slice(0, 10)
              .map((item, idx) => (
                <div
                  onClick={() => {
                    navigate(`/article?id=${item.id}`);
                  }}
                  className="scroll-item"
                  key={idx}
                  style={{width: witnessItemWidth, marginBottom: 50}}>
                  <div
                    className="grid-item-img"
                    style={{backgroundImage: `url(${item.image})`}}
                  />

                  <p className="grid-item-title" style={{marginTop: 15}}>
                    {item.title}
                  </p>
                  <p className="grid-item-outline" style={{marginTop: 10}}>
                    {item.outline}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </section>

      <div
        id="rev-banner"
        className="banner"
        style={{height: bannerDimension?.width * 0.38}}>
        <img
          src="../../images/landing.png"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > section {
    position: relative;
    overflow-x: hidden;
  }

  & .read-more-btn {
    display: inline-block;
    color: var(--secondFontColor);
    border-bottom: 0.5px solid var(--primaryColor);
    font-size: 13px;
    font-weight: lighter;
    letter-spacing: 1.86px;
    line-height: 24px;
  }

  .outline {
    flex: 4;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export default Landing;
